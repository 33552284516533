// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeRating_eSr06{display:grid;grid-auto-rows:-webkit-max-content;grid-auto-rows:max-content;grid-template-areas:\"table news\" \"banner news\" \". news\";grid-template-columns:1fr 2fr;grid-gap:2rem}@media(max-width:767.98px){.HomeRating_eSr06{grid-template-areas:\"table\" \"banner\" \"news\";grid-template-columns:1fr}}.title_RvNFp{font-size:2.8rem;font-weight:700;letter-spacing:-.04em;line-height:1.1em;margin-bottom:2.8rem;text-transform:uppercase}@media(max-width:1279.98px){.title_RvNFp{font-size:2.4rem;font-weight:700;letter-spacing:-.04em;line-height:1.1em;text-transform:uppercase}}.grid_fjsw\\+{display:grid;gap:2rem;grid-template-columns:repeat(5,minmax(0,1fr))}@media(max-width:1439.98px){.grid_fjsw\\+{display:none}}.slider_8oVvF{display:none}@media(max-width:1439.98px){.slider_8oVvF{display:block}}@media(max-width:1279.98px){.slider_8oVvF{margin-left:-3.2rem;padding:0 3.2rem;width:calc(100% + 6.4rem)}}@media(max-width:767.98px){.slider_8oVvF{margin-left:-2rem;padding:0 2rem;width:calc(100% + 4rem)}}.slide_U0xXE{height:auto}.card_t6xNS{height:100%;width:100%}.pagination_JM619{margin-top:1.6rem}@media(max-width:767.98px){.pagination_JM619{margin-top:1.2rem}}.button_HKsY1{margin-top:3.2rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media(max-width:1279.98px){.button_HKsY1{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"HomeRating": "HomeRating_eSr06",
	"title": "title_RvNFp",
	"grid": "grid_fjsw+",
	"slider": "slider_8oVvF",
	"slide": "slide_U0xXE",
	"card": "card_t6xNS",
	"pagination": "pagination_JM619",
	"button": "button_HKsY1"
};
module.exports = ___CSS_LOADER_EXPORT___;

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RatingList_TLRVf{display:grid;grid-gap:3.2rem;background:#fff;border-radius:2rem;grid-area:table;padding:2.4rem}@media(max-width:767.98px){.RatingList_TLRVf{padding:2rem}}.button_OigA7{align-items:center;cursor:pointer;display:grid;gap:7px;grid-template-columns:-webkit-max-content -webkit-max-content;grid-template-columns:max-content max-content}.icon_4j0U2{color:#abaebb}.table_bMRCc{display:grid;grid-gap:2rem}.tableGrid_dNfMn{grid-template-columns:-webkit-max-content 1fr -webkit-max-content;grid-template-columns:max-content 1fr max-content}.tableGrid_dNfMn,.tableList_SEc86{display:grid;grid-gap:1.2rem}.tableCell_qdLcj{font-size:1.6rem;font-weight:500;letter-spacing:-.04em;line-height:120%}.top_a1gQ8{display:grid;grid-gap:.8rem}.title_RB\\+nY{font-size:2rem;font-weight:500;letter-spacing:-.08rem;line-height:2.4rem}.text_TXjJi{color:#73788c;font-size:1.6rem;font-weight:400;letter-spacing:-.04em;line-height:120%}.link_ptdBb{width:-webkit-max-content;width:max-content}@media(max-width:767.98px){.link_ptdBb{width:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"RatingList": "RatingList_TLRVf",
	"button": "button_OigA7",
	"icon": "icon_4j0U2",
	"table": "table_bMRCc",
	"tableGrid": "tableGrid_dNfMn",
	"tableList": "tableList_SEc86",
	"tableCell": "tableCell_qdLcj",
	"top": "top_a1gQ8",
	"title": "title_RB+nY",
	"text": "text_TXjJi",
	"link": "link_ptdBb"
};
module.exports = ___CSS_LOADER_EXPORT___;
